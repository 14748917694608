<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="configurePage" :fields="fields" :search-model="searchModel"
             :show-selection="true"   :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="类型" prop="state">
          <el-select size="small" v-model="searchModel.utype" clearable placeholder="类型">
            <el-option label="开发商" value="0"></el-option>
            <el-option label="经销商" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开发商名称" prop="developersId">
          <el-select size="small" v-model="searchModel.developersId" filterable clearable placeholder="开发商名称">
            <el-option
                v-for="item in developer_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经销商名称" prop="distributorId">
          <el-select size="small" v-model="searchModel.distributorId" filterable clearable placeholder="经销商名称">
            <el-option
                v-for="item in distributor_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效状态" prop="state">
          <el-select size="small" v-model="searchModel.state" clearable placeholder="有效状态">
            <el-option label="有效" value="0"></el-option>
            <el-option label="失效" value="1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <div style="margin-bottom: 18px;">
          <el-button @click="handleAdd"  size="small" icon="el-icon-plus" type="primary">新建</el-button>
        </div>
      </template>
      <template slot="page-actions-right">
        <div style="margin-bottom: 18px;">
          <el-button @click="exportData" style="background-color: #E6EBF0;color: #18191A;border: none;" size="small" icon="el-icon-upload2" >批量导出</el-button>
        </div>
      </template>
    </page-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {
  configurePage,
  configureChangeState,
  distributorAll,
  developerAll,
  configureExport,
} from "@/api/common"
import downloadBlob from "@/function/downloadBlob";
import StateShow from "@/components/StateShow.vue";

export default {
  name: "DeveloperConfigures",
  data() {
    return {
      searchModel: {
        developersId: '',
        state: '',
        utype: ''
      },
      configurePage: configurePage,
      fields: [
        {label: '序号', key: 'id', width: 180,},
        {label: '所在省', width: 100,
          render: ({developers}, h) => {
            return h('div', developers ? developers.province : '')
          }
        },
        {label: '开发商ID', key: 'developersId', width: 200,},
        {
          label: '开发商名称',
          width: 210,
          render: ({developers}, h) => {
            return h('div', developers ? developers.name : '')
          }
        },
        // {label: '经销商ID', key: 'distributorId', width: 200},
        {
          label: '经销商',
          width: 210,
          render: ({distributor}, h) => {
            return h('div', distributor ? distributor.name : '')
          }
        },
        {
          label: '租金价格(￥)',
          width: 160,
          render: (item, h) => {
            return h('div', item.type == 0 ? (item.rent / 100).toFixed(2) : (item.rent1 / 100).toFixed(2) + ',' + (item.rent2 / 100).toFixed(2))
          }
        },
        {
          label: '租金类型',
          width: 100,
          render: (item, h) => {
            return h("span", ['通用型', '10+15型', '5+20型'][item.type])
          }
        },
        {
          label: '有效期',
          width: 250,
          render: (item, h) => {
            return h("span", item.startDate + '--' + item.endDate)
          }
        },
        {
          label: '有效状态',
          key: 'secretKey',
          width: 90,
          render: ({state}) => {
            if (!state) {
              return (<StateShow custom={true} status="COMPLETE" text="有效" />)
            } else {
              return (<StateShow custom={true} status="REFUSE" text="失效" />)
            }
          }
        },
        {label: '操作时间', key: 'uTime', width: 180, align: 'center'},
      ],
      actions: [
        {
          action: 'changeState',
          label: '生效',
          type: 'primary',
          showAction(item) {
            return item.state
          }
        },
        {
          action: 'changeState',
          label: '失效',
          type: 'primary',
          showAction(item) {
            return !item.state
          }
        },
        {
          action: 'info',
          label: '查看详情',
          type: 'primary',
        },
        {
          action: 'edit',
          label: '编辑',
          type: 'primary',
        },
      ],
      //
      developer_options: [],
      distributor_options: [],
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {
    this.getDeveloperOptions()
    this.getDistributorOptions()
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    getDeveloperOptions() {
      let that = this
      developerAll().then(res => {
        that.developer_options = res
      })
    },
    getDistributorOptions() {
      let that = this
      distributorAll().then(res => {
        that.distributor_options = res
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    handleAdd() {
      this.$router.push('/admin/rentConfig')
    },
    editAction(item) {
      this.$router.push(`/admin/rentConfig/${item.id}`)
    },
    infoAction(item) {
      if(item.utype==0){
        this.$router.push({path: '/admin/rent-set-developer-info/' + item.id})
      }else {
        this.$router.push({path: '/admin/rent-set-distributor-info/' + item.id})
      }
    },
    changeStateAction(item) {
      let that = this
      that.$confirm('此操作将更改状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        configureChangeState(item.id, item.state ? 0 : 1).then(() => {
          that.$message.success('操作成功');
          this.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    exportData(){
      let that = this,ids = []
      let selected = that.$refs.table.selection
      for (let item of selected){
        ids.push(item.id)
      }
      let data = Object.assign(that.searchModel, {
        exportIds: ids.length ? ids.join(',') : ''
      })
      configureExport(data).then(res => {
        downloadBlob(res)
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
